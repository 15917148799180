/**
 * Replace subdomain in a url
 * @param url url to be modified
 * @param subdomains subdomains to be replaced
 * @param replacement replacement for the subdomains
 * @returns modified url
 */
export const replaceSubdomain = (url: string, subdomains = ['live', 'preview', 'www'], replacement = 'www') => {
  const urlObject = new URL(url);
  let { hostname } = urlObject;
  subdomains.forEach((s) => {
    hostname = hostname.replace(`${s}.`, '');
  });
  return `${urlObject.protocol}//${replacement}.${hostname}`;
};
