import * as React from 'react';
import { LanguageService } from '../../services/language';
import { AlternateLanguageLink } from '../../services/dom/types';
import { PageContextStory, NavigationStory, Story } from '../../services/build/types';
import { blokToComponent } from '../../helpers/blok-to-component';
import { getComponent } from '../../helpers/get-component';
import { NavigationService } from '../../services/navigation';

const MobileNavigation = getComponent('navigation-mobile') as React.ElementType;
const Navigation = getComponent('navigation') as React.ElementType;

type HeaderSectionProps = {
  home: Story<PageContextStory>;
  storyContent: PageContextStory;
  navigationContent: NavigationStory;
  alternates: AlternateLanguageLink[];
};

export const HeaderSection: React.FC<HeaderSectionProps> = ({ home, storyContent, navigationContent, alternates }) => (
  <>
    <roche-animation-manager />
    <roche-offcanvas-panel id='roche-offcanvas-menu'>
      <MobileNavigation
        tree={[navigationContent?.primary_navigation || [], navigationContent?.secondary_navigation || []]}
        worldWideLink={navigationContent?.roche_world_wide_link}
        alternates={alternates}
      />
    </roche-offcanvas-panel>

    {!storyContent.remove_header && (
      <roche-header
        home-url={LanguageService.getHomePageUrl(home)}
        is-microsite={storyContent?.microsite_header}
        fixed={storyContent?.fixed_header}
        global-search-link={
          NavigationService.getParsedLink(navigationContent?.global_search_link)
            ? NavigationService.getParsedNavigationLink(navigationContent?.global_search_link)
            : null
        }
      >
        {storyContent?.microsite_header &&
          blokToComponent({
            getComponent,
            blok: storyContent?.microsite_title?.[0],
            slot: 'microsite-title',
          })}
        {!storyContent?.hide_navigation && (
          <>
            <Navigation tree={navigationContent?.primary_navigation} slot='primary' />
            <Navigation
              tree={navigationContent?.secondary_navigation}
              slot='secondary'
              worldWideLink={navigationContent?.roche_world_wide_link}
              globalSearchLink={navigationContent?.global_search_link}
              alternates={alternates}
            />
          </>
        )}
      </roche-header>
    )}
  </>
);
