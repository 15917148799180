import { HeadProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { DirectionalityService } from '../../services/directionality';
import { PageContext } from '../../services/build/types';

/**
 * @returns Head API specific component
 *
 * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#additional-resources
 */
export const Head = (props: HeadProps) => {
  const lang = (props.pageContext as PageContext)?.story?.lang ?? process.env.GATSBY_STORYBLOK_SPACE_DEFAULT_LANGUAGE;
  return <html dir={DirectionalityService.getDirectionality(lang)} />;
};

interface HelmetHeadProps {
  lang?: string;
  title: string;
}

/**
 * Use only for dynamic pages. (ex: src/pages/editor.tsx, ExternalData template component)
 * @returns React Helmet based "head" attributes and tags.
 */
export const HelmetHead = (props: HelmetHeadProps) => (
  <Helmet
    htmlAttributes={{
      dir: DirectionalityService.getDirectionality(props?.lang || process.env.GATSBY_STORYBLOK_SPACE_DEFAULT_LANGUAGE),
    }}
    title={props.title}
  />
);
