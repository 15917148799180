import StoryblokClient from 'storyblok-js-client';
import { LanguageService } from '../language';
import { NavigationService } from '../navigation';
import { AlternateComponent, ComponentWithAlternates, SetState } from './types';

export class EditorService {
  static loadStoryblokBridge(onLoadHandler: () => void): void {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.storyblok.com/f/storyblok-v2-latest.js');
    script.onload = onLoadHandler;
    document.head.appendChild(script);
  }

  static async loadBreadcrumbs(path: string, callback: SetState): Promise<void> {
    const breadcrumbs = await NavigationService.getBreadcrumbsFromPath(path);
    callback({ breadcrumbs });
  }

  static async loadFontOverwrites(client: StoryblokClient): Promise<Record<string, string> | undefined> {
    const defaultLocale = LanguageService.getDefaultLocale();
    const locale = LanguageService.getActiveLanguage();
    const hasDimension = locale !== defaultLocale;

    const fontOverwrites = await client.get('cdn/datasource_entries', {
      datasource: 'alternate-fonts',
      page: 1,
      per_page: 20,
      dimension: hasDimension ? `${locale}` : '',
    });

    const overwrites = fontOverwrites.data.datasource_entries || [];

    if (overwrites.length <= 0) {
      return undefined;
    }

    const fontFaceOverwrites = overwrites.reduce((accumulator: Record<string, string>, entry: any) => {
      const value = hasDimension ? entry.dimension_value : entry.value;
      if (!value || value.trim() === 'default') {
        return accumulator;
      }

      return {
        ...accumulator,
        [entry.name]: value,
      };
    }, {});

    return fontFaceOverwrites;
  }

  static async loadGlobalComponents(client: StoryblokClient, callback: SetState): Promise<void> {
    const queryParams = {
      filter_query: {
        component: {
          in: 'global-components',
        },
      },
    };

    const globalComponents = await client.getAll('cdn/stories', queryParams);

    if (globalComponents.length <= 0) {
      alert('Your website will break because no "Global Components" were found. Please warn your administrator.');
      return;
    }

    if (globalComponents.length > 1) {
      alert(
        'More than 1 instance of Global Components found. Your website will break! Please warn your administrator.',
      );
    }

    callback({ globalComponents: globalComponents[0] });
  }

  static async loadGlobalComponent(
    uuid: string,
    stateKeyName: string,
    client: StoryblokClient,
    callback: SetState,
    params: Record<string, any> = {},
    locale: string,
  ): Promise<void> {
    if (!uuid) {
      return;
    }

    const queryParams = {
      ...params,
      by_uuids: uuid,
    };

    const [component] = (await client.getAll('cdn/stories', queryParams)) as ComponentWithAlternates[];
    const alternate = component?.alternates.find(
      ({ full_slug: slug }: AlternateComponent) => locale === LanguageService.getLocaleFromSlug(slug),
    );

    if (!alternate || locale === LanguageService.getDefaultLocale()) {
      callback({ [stateKeyName]: component });
      return;
    }

    const alternateComponent = await client.get(`cdn/stories/${alternate.full_slug}`, {
      ...params,
    });
    callback({ [stateKeyName]: alternateComponent.data?.story });
  }

  static async loadDefaultLanguageHome(
    uuid: string,
    stateKeyName: string,
    client: StoryblokClient,
    callback: SetState,
    params: Record<string, any> = {},
  ): Promise<void> {
    if (!uuid) {
      return;
    }

    const queryParams = {
      ...params,
      by_uuids: uuid,
    };

    const [component] = (await client.getAll('cdn/stories', queryParams)) as ComponentWithAlternates[];
    callback({ [stateKeyName]: component });
  }
}
