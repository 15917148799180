import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { replaceSubdomain } from '../../utils/replace-subdomain';

type SEOProps = {
  title?: string;
  description?: string;
  lang?: string;
  slug?: string;
  contentType?: 'pages' | 'stories';
  story_type?: string;
  teaser_image?: string;
  teaser_title?: string;
  teaser_description?: string;
  releaseType?: 'media' | 'investors';
  releaseDate?: string;
  first_published_at?: string;
  authorized_roles?: string[];
  metaTags?: Record<string, string>;
  og_title?: string;
  og_description?: string;
  og_image?: string;
  twitter_title?: string;
  twitter_description?: string;
  twitter_image?: string;
};

export function SEO({
  title,
  description,
  slug,
  contentType,
  story_type: storyType,
  teaser_image: teaserImage,
  teaser_title: teaserTitle,
  teaser_description: teaserDescription,
  releaseType,
  releaseDate,
  first_published_at: firstPublishedAt,
  authorized_roles: authorizedRoles,
  og_title: ogTitle,
  og_description: ogDescription,
  og_image: ogImage,
  twitter_title: twitterTitle,
  twitter_description: twitterDescription,
  twitter_image: twitterImage,
}: SEOProps): JSX.Element {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          url
          defaultLanguage
          twitterHandle
        }
      }
    }
  `);

  const websiteUrl = replaceSubdomain(process.env.GATSBY_WEBSITE_URL);
  const isPrivate = authorizedRoles?.includes('authorized') ? 'yes' : 'no';

  return (
    <Helmet
      title={title || teaserTitle || ogTitle}
      meta={
        [
          title && { name: 'title', content: title },
          description && { name: 'description', content: description },
          ogImage && { property: 'og:image', content: ogImage },
          (ogTitle || title) && { property: 'og:title', content: ogTitle || title },
          (ogDescription || description) && { property: 'og:description', content: ogDescription || description },
          { property: 'og:type', content: 'website' },
          slug && { property: 'og:url', content: `${websiteUrl}/${slug}` },
          twitterImage && { name: 'twitter:image', content: twitterImage },
          { name: 'twitter:card', content: 'summary' },
          site.siteMetadata.twitterHandle && { name: 'twitter:creator', content: site.siteMetadata.twitterHandle },
          twitterTitle && { name: 'twitter:title', content: twitterTitle },
          twitterDescription && { name: 'twitter:description', content: twitterDescription },
          { name: 'private', content: isPrivate },
          contentType && { name: 'content-type', content: contentType },
          storyType && { name: 'story-type', content: storyType },
          firstPublishedAt && { name: 'published-date', content: firstPublishedAt },
          teaserImage && { name: 'teaser-image', content: teaserImage },
          teaserTitle && { name: 'teaser-title', content: teaserTitle },
          teaserDescription && { name: 'teaser-description', content: teaserDescription },
          releaseType && { name: 'release-type', content: releaseType },
          releaseDate && { name: 'release-date', content: releaseDate },
        ].filter(Boolean) as { name?: string; property?: string; content: string }[]
      }
    />
  );
}
